<template>
  <v-dialog
    max-width="700"
    width="100%"
    :value="true"
    @click:outside="close()"
    @keydown.esc="close()"
    persistent
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <v-toolbar color="error" class="mb-2" flat>
          <v-spacer /><PersonItem v-if="student" :value="student" /><v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-alert v-if="editMode" class="mt-2" type="error" text prominent>
          <v-text-field
            @keydown.enter="saveComment()"
            append-icon="mdi-floppy"
            @click:append="saveComment()"
            append-outer-icon="mdi-close"
            @click:append-outer="cancelComment()"
            light
            v-model="comment"
            label="Kommentar"
          />
        </v-alert>
        <v-alert v-else class="mt-2" type="info" text prominent>
          <v-text-field
            append-outer-icon="mdi-lock"
            @click="editMode = true"
            light
            readonly
            v-model="comment"
            label="Kommentar"
          />
        </v-alert>

        <v-timeline>
          <v-timeline-item left fill-dot color="success">
            <v-card>
              <v-card-text>
                <LockerInput
                  v-model="locker"
                  @input="create"
                  label="Schäftli hinzufügen"
              /></v-card-text>
            </v-card>
          </v-timeline-item>

          <v-timeline-item
            v-for="item in current"
            left
            fill-dot
            :key="item.id"
            color="primary"
          >
            <v-card>
              <v-list-item
                :to="{
                  name: 'LockerDetails',
                  params: { id: item.locker.id },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title> {{ item.locker.code }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    subtitle(item.locker)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="finish(item.id, item.locker)"
                  >entfernen</v-btn
                >
              </v-card-actions>
            </v-card>
            <template v-slot:opposite>
              {{ formatDatespan(item.startDate, item.endDate) }}
            </template>
          </v-timeline-item>
          <v-timeline-item fill-dot color="error">
            <template v-slot:icon>
              <v-icon @click="showHistory = !showHistory" dark>{{
                showHistory ? "mdi-dots-vertical" : "mdi-dots-horizontal"
              }}</v-icon>
            </template>
            <template v-slot:opposite>
              <v-btn text small @click="showHistory = !showHistory">
                {{ showHistory ? "ältere ausblenden" : "ältere einblenden" }}
              </v-btn>
            </template>
          </v-timeline-item>
          <template v-if="showHistory">
            <v-timeline-item
              v-for="item in history"
              right
              fill-dot
              :key="item.id"
              color="grey"
            >
              <v-list-item
                :to="{
                  name: 'LockerDetails',
                  params: { id: item.locker.id },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title> {{ item.locker.code }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    subtitle(item.locker)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <template v-slot:opposite>
                {{ formatDatespan(item.startDate, item.endDate) }}
              </template>
            </v-timeline-item>
          </template>
        </v-timeline>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn @click="prev()" text
          ><v-icon left>mdi-chevron-left</v-icon>vorheriger Eintrag</v-btn
        >
        <v-spacer />
        <v-btn @click="next()" text
          >nächster Eintrag<v-icon right>mdi-chevron-right</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonItem from "@/components/PersonItem.vue";
import LockerInput from "@/components/LockerInput.vue";
import { formatDatespan } from "common/utils/date.js";
import { personName } from "common/utils/people.js";
export default {
  name: "LockerDetails",
  components: { LockerInput, PersonItem },
  props: ["id"],
  data() {
    return {
      locker: null,
      student: false,
      current: [],
      comment: "",
      editMode: false,
      history: [],
      update: false,
      showHistory: false,
      loading: false,
    };
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    async prev() {
      if (await this.checkComment()) {
        this.$emit("prev");
      }
    },
    async next() {
      if (await this.checkComment()) {
        this.$emit("next");
      }
    },
    personName,
    async checkComment() {
      return (
        !this.editMode ||
        this.$root.confirm({
          message: `Kommentar ist nicht gespeichert. Trotzdem verlassen? `,
          color: "error",
          icon: "mdi-alert",
        })
      );
    },
    async close() {
      if (await this.checkComment()) {
        if (this.update) {
          this.$emit("update");
        }
        this.$router.push({
          name: "LockersStudents",
        });
      }
    },
    async saveComment() {
      await this.apiPost({
        resource: "locker/comment",
        data: { student: this.id, comment: this.comment },
      });
      this.editMode = false;
      this.update = true;
      this.$root.showSuccess("Kommentar abgespeichert");
    },
    cancelComment() {
      this.editMode = false;
      this.fetchData();
    },
    async create(locker) {
      if (
        locker &&
        (await this.$root.confirm({
          message: `Soll ${personName(this.student)} das Schäftli ${
            locker.code
          } zugewiesen werden?`,
          color: "error",
          icon: "mdi-account-plus",
        }))
      ) {
        await this.apiPost({
          resource: "locker/usage",
          data: {
            locker: locker.id,
            student: this.student.id,
          },
        });
        this.$root.showSuccess(
          `${personName(this.student)} erfolgreich dem Schäftli ${
            locker.code
          } zugewiesen`
        );
        this.fetchData();
        this.update = true;
      }
    },
    async fetchData() {
      this.loading = true;
      const data = await this.apiGet({
        resource: "person/locker",
        id: this.id,
      });
      this.locker = null;
      this.editMode = false;
      this.comment = data.comment;
      this.student = data.student;
      this.current = data.current.sort((a, b) =>
        a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0
      );
      this.history = data.history.sort((a, b) =>
        a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0
      );
      this.loading = false;
    },
    async finish(id, locker) {
      if (
        await this.$root.confirm({
          message: `Soll  ${personName(this.student)} vom Schäftli ${
            locker.code
          } ausgetragen werden?`,
          color: "error",
          icon: "mdi-account-remove",
        })
      ) {
        await this.apiPut({
          resource: "locker/usage",
          data: { id: id, finish: true },
        });
        this.$root.showSuccess(
          ` ${personName(this.student)} erfolgreich aus Schäftli ${
            locker.code
          } ausgetragen`
        );
        this.fetchData();
        this.update = true;
      }
    },
    formatDatespan: formatDatespan,
    subtitle(locker) {
      if (!locker) {
        return "";
      }
      let result = "";
      if (locker.code.startsWith("P")) {
        result = "Pavillon";
      } else if (locker.code.startsWith("0")) {
        result = "Geschoss 00";
      } else if (locker.code.startsWith("1")) {
        result = "Geschoss 01";
      } else if (locker.code.startsWith("2")) {
        result = "Geschoss 02";
      } else if (locker.code.startsWith("3")) {
        result = "Geschoss 03";
      } else if (locker.code.startsWith("4")) {
        result = "Geschoss 04";
      }

      return result;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
